<footer>
  <div class="main-container footer-container">
    <div class="brand-container">
      <img class="footer-logo" src="../../../assets/logo.svg">
      <span class="footer-logo-copy">Freude an Finanzen.</span>
    </div>

    <div class="social-media-container">
      <a class="facebook" href="https://www.facebook.com/TauRes.GmbH" target="_blank"></a>
      <a class="spotify" href="https://open.spotify.com/playlist/1Pw8KtXGfLFIjb6o9U0g6j" target="_blank"></a>
    </div>
  </div>
  <div class="subfooter">
    <div class="main-container">
      © 2020 TauRes Gesellschaft für Investmentberatung mbH
    </div>
  </div>
</footer>
