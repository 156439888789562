import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {ContactData} from '../../service/applicant.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactCardComponent {
  @Input()
  public jobTitle: string;
  protected trustedImageUrlURL: SafeResourceUrl;
  private $contactData: ContactData;

  public constructor(readonly sanitizer: DomSanitizer) {
  }

  public get contactData(): ContactData {
    return this.$contactData;
  }

  @Input()
  public set contactData(value: ContactData) {
    this.$contactData = value;
  }

  @Input()
  public set imageUrl(value: string) {
    this.trustedImageUrlURL = this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  protected handleImageError(event: Event): void {
    (event.target as HTMLImageElement).src = '../../../assets/avatar-default.jpg';
  }
}
